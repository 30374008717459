export const images = [
    {
      id: '01',
      src: '/Rexburg_Alpine_Chalet_Game Room.jpg',
      alt: 'Rexburg',
    },
    {
      id: '02',
      src: '/Rexburg_Portfolio_Aerial.jpg',
      alt: 'Rexburg',
    },
    {
      id: '03',
      src: '/Rexburg_Alpine_Chalet_Exterior.jpg',
      alt: 'Rexburg',
    },
    {
      id: '04',
      src: '/Rexburg_Rockland_Exterior.jpg',
      alt: 'Rexburg',
    },
    {
      id: '05',
      src: '/Rexburg_Somerset_Exterior.jpg',
      alt: 'Rexburg',
    },
    {
      id: '05',
      src: '/Rexburg_Alpine_Chalet_Interior.jpg',
      alt: 'Rexburg',
    },
    {
      id: '05',
      src: '/Rexburg_Rockland_Game_Room.jpg',
      alt: 'Rexburg',
    },
    {
      id: '05',
      src: '/Rexburg_Rockland_Interior.jpg',
      alt: 'Rexburg',
    },
    {
      id: '05',
      src: '/Rexburg_Somerset_Game_Room.jpg',
      alt: 'Rexburg',
    },
    {
      id: '05',
      src: '/Rexburg_Somerset_Interior.jpg',
      alt: 'Rexburg',
    },
  ]
