import React, { useState } from 'react';
import { Box, Text, Image, Link, SimpleGrid, Modal,ModalOverlay,ModalContent,ModalHeader,ModalBody,ModalCloseButton, } from '@chakra-ui/react';


function ImageModal({ title, description, isOpen, onClose }) {
  return (
    <Modal  isOpen={isOpen} onClose={onClose}>
      <ModalOverlay  />
      <ModalContent mt={'120px'}>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize={["16px","18px","18px"]}
                lineHeight={['29px','30px','35px']}
                fontFamily={'montserrat, sans-serif'} 
                pb={'150px'} pt={'125px'}  
                textAlign={'center'}>{description}</Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}


const Services = () => {

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    description: '',
  });

  const openModal = (title, description) => {
    setModalInfo({
      isOpen: true,
      title,
      description,
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      description: '',
    });
  };




  return (
    <Box >
      {/* Title */}
      <Text fontFamily={'montserrat, sans-serif'} fontSize={["28px","30px","36px"]} textAlign="center" mb="4" fontWeight={'bold'} letterSpacing={['1.5px','1.8px','1.8px']}>
        SERVICES
      </Text>

      {/* Images */}
      {/* <Flex flexDirection={{ base: 'column', md: 'row' }} alignItems={['center', '', '']} justifyContent={"space-between"} paddingLeft={"9%"} paddingRight={"9%"} paddingTop={'35px'} paddingBottom={['30px','80px','80px']}> */}
      <SimpleGrid
        columns={{ base: 2, md: 4 }}
        spacingX="4"
        spacingY={{ base: '4', md: '0' }}
        justifyItems="center"
        justifyContent={{ base: 'center', md: 'space-between' }}
        paddingLeft={{ base: '9%', md: '9%' }}
        paddingRight={{ base: '9%', md: '9%' }}
        paddingTop={['30px', '80px', '80px']}
        paddingBottom={['30px', '60px', '70px']}
      >
      <Link onClick={() => openModal('Service 2', 'An accurate property evaluation allows investors to take an objective look at their portfolio and determine the best course of action. Whether you plan on consolidating assets or upgrading older properties, be sure to reach out to the team for a current valuation.')}>
        <Image src="/Group 24.png" alt="Service 1"  h={{ base: '160px', md: '225px' }} />
        </Link>
        <Link onClick={() => openModal('Service 2', 'The past five years have shown how volatile the real estate market can be in a short period of time. Come to the experts to understand the best financial decision for your portfolio.')}>
        <Image src="/Group 25.png" alt="Service 2" h={{ base: '160px', md: '225px' }} />
        </Link>
        <Link onClick={() => openModal('Service 2', 'Allow us to help you acquire and downsize assets from start to finish with our excellent marketing process. Our refined process ensures that clients have transparent timelines and stay included in the listing process through every step.')}>
        <Image src="/Group 26.png" alt="Service 3" h={{ base: '160px', md: '225px' }}/>
        </Link>
        <Link onClick={() => openModal('Service 2', 'Through our expansive network in the multifamily community, we have the resources to connect developers in need of capital and investors looking to expand a passive portfolio.')}>
        <Image src="/Group 27.png" alt="Service 4" h={{ base: '160px', md: '225px' }} />
        </Link>
        <ImageModal {...modalInfo} onClose={closeModal} />
      </SimpleGrid>
       
      {/* </Flex> */}
      <Text     
                fontFamily={'montserrat, sans-serif'}
                pb={{
                base: '4',
                md: '5',
                }} 
                textAlign={'left'}
                color="fg.muted"
                lineHeight={['27px','30px','35px']}
                fontSize={["15px","",""]}
                px={["6","",""]}
                paddingLeft={"9%"} paddingRight={"9%"}
                >
          As #1 Brokers in the state of Utah and leading the state in market share for 2022 Sales Volume, 
          the Shin-Zylstra: IPA Team rises to the top among their competition time after time. As a team, 
          the group has successfully aided in transacting over 21,000 units totalling over $3.74 B. With 
          refined experience in western regional multifamily real estate, Brock and Danny are closely connected 
          with the most active developers and institutions that are underwriting current market conditions. 
          This ensures that any evaluation, transaction, and advice is honest and openly reflects true rates 
          for the given market. 
              </Text>
              <Text pb={{
                base: '4',
                md: '5',
                }} 
                fontFamily={'montserrat, sans-serif'}
                textAlign={'left'}
                color="fg.muted"
                lineHeight={['27px','30px','35px']}
                fontSize={["15px","",""]}
                px={["6","",""]}
                paddingLeft={"9%"} paddingRight={"9%"}
                >
          As the team strives for excellence, recognition has followed through awards and achievements year after 
          year. Each member consistently receives recognition from prestigious groups such as CoStar, The Salt 
          Lake Chamber, and the National Apartment Association. 
              </Text>
    </Box>
  );
};

export default Services;