import { Box, Flex, Image, Text } from '@chakra-ui/react';

const MarketingTeam = () => {
  return (
    <Box textAlign="center" px={["6","",""]} pt={["6","",""]}>
      {/* Title */}
      <Text fontFamily={'montserrat, sans-serif'} fontSize="3xl" fontWeight="bold" mb="6" pt={["6","","100px"]} pb={["30px","40px","60px"]}>
        MARKETING TEAM
      </Text>

      {/* Team Members */}
      <Flex justifyContent="center" flexDirection={['column','row','row']}>
        {/* Team Member 1 */}
        <Box flex="1" textAlign="left" mr="4" maxWidth={'450px'}>
          {/* Image */}
          <Image
            src="/dani-paskevitch-portrait.png"
            alt="Team Member 1"
            mx="auto"
            h={['375px','300px','365px']}
            mb="4"
          />

          {/* Name */}
          <Text fontFamily={'montserrat, sans-serif'} fontSize={["20px","22px","24px"]} fontWeight="bold" mb="2" color={'#475253'} marginLeft={['9%','19%','19%']}>
          Dani Paskevitch
          </Text>

          {/* Description */}
            <Text fontFamily={'montserrat, sans-serif'} fontSize={["12px","13px","14px"]} align={'left'} lineHeight={['28px','30px','32px']}  marginLeft={['9%','19%','19%']} maxWidth={'300px'}>
          With years of creative experience in a variety of industries, Dani executes 
          promotional materials with excellency. Her work has allowed the team to reach 
          more clients, streamlining communication and simplifying the creative process. 
          </Text>
        </Box>

        {/* Team Member 2 */}
        <Box flex="1" textAlign="left" ml={["0","4","4"]} mr={["4","0","0"]}  maxWidth={'450px'} pt={['50px','0px','0px']}>
          {/* Image */}
          <Image
            src="/em-simmons-portrait.png"
            alt="Team Member 2"
            mx="auto"
            h={['375px','300px','365px']}
            mb="4"
          />

          {/* Name */}
          <Text fontFamily={'montserrat, sans-serif'} fontSize={["20px","22px","24px"]} fontWeight="bold" mb="2" color={'#475253'} marginLeft={['9%','19%','19%']}>
          Em Simmons
          </Text>

          {/* Description */}
          <Text fontFamily={'montserrat, sans-serif'} fontSize={["12px","13px","14px"]} align={'left'} lineHeight={['28px','30px','32px']}  marginLeft={['9%','19%','19%']} maxWidth={'300px'}>
          Em has ample experience in business copywriting and a degree in Marketing. 
          She uses this exceptional ability to execute writing, act as a client relationship 
          liaison, and formulate research materials that convert leads to sales.
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default MarketingTeam;