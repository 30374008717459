export const images = [
    {
      id: '01',
      src: '/Mt. Eyrie_1.jpg',
      alt: 'Lofts on 34th',
    },
    // {
    //   id: '02',
    //   src: '/shulsen-lane-2.jpg',
    //   alt: 'Shulsen Lane',
    // },
    {
      id: '03',
      src: '/Mt. Eyrie_2.jpg',
      alt: 'Lofts on 34th',
    },
    {
      id: '04',
      src: '/Mt. Eyrie_3.jpg',
      alt: 'Lofts on 34th',
    },
    
    {
        id: '06',
        src: '/Mt. Eyrie_4.jpg',
        alt: 'Lofts on 34th',
      },
      {
        id: '07',
        src: '/Mt. Eyrie_5.jpg',
        alt: 'Lofts on 34th',
      },
      {
        id: '07',
        src: '/Mt. Eyrie_6.jpg',
        alt: 'Lofts on 34th',
      },
      {
        id: '07',
        src: '/Mt. Eyrie_7.jpg',
        alt: 'Lofts on 34th',
      },
    //   {
    //     id: '05',
    //     src: '/DesertSage (5).jpg',
    //     alt: 'Shulsen Lane',
    //   },
    // {
    //   id: '06',
    //   src: '/Alpine_Flats_Theater_Room.jpg',
    //   alt: 'Revive Apartments',
    // },
   
  ]