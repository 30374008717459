export const Pproducts = [
    {
      id: '1',
      name: 'Ashli Apartments',
      title: '',
      imageUrl:
        './1. Ashli.png',
      description: '',
        productLink: 'https://www.crexi.com/properties/1554104/utah-ashli-apartments',
        isClosed: false,
    },
    {
        id: '1',
        name: 'Quincy Avenue',
        title: '',
        imageUrl:
          './2. Quincy Ave.png',
        description: '',
          productLink: 'https://www.crexi.com/properties/1551771/utah-quincy-avenue',
          isClosed: false,
      },
      {
        id: '1',
        name: '955 Apartments',
        title: '',
        imageUrl:
          './3. 955 Apts.png',
        description: '',
          productLink: 'https://www.crexi.com/properties/1551778/utah-955-apartments',
          isClosed: false,
      },
      {
        id: '1',
        name: 'N Center Street Four-Plex',
        title: '',
        imageUrl:
          './4. N Center St.png',
        description: '',
          productLink: 'https://www.crexi.com/properties/1551799/utah-n-center-street',
          isClosed: false,
      },
      {
        id: '1',
        name: '28th Street Duplex',
        title: '',
        imageUrl:
          './5. 28th Street.png',
        description: '',
          productLink: 'https://www.crexi.com/properties/1551807/utah-28th-street',
          isClosed: false,
      },
      {
        id: '1',
        name: '6773 South 1300 West',
        title: '',
        imageUrl:
          './6. 6773 S 1300.png',
        description: '',
          productLink: 'https://www.crexi.com/properties/1552909/utah-6773-s-1300-w',
          isClosed: false,
      },
 
    ]