export const images = [
    {
      id: '01',
      src: '/idaho-falls-portfolio-one.jpg',
      alt: 'idaho-falls-portfolio',
    },
    {
      id: '02',
      src: '/idaho-falls-portfolio-two.jpg',
      alt: 'idaho-falls-portfolio',
    },
    {
      id: '03',
      src: '/idaho-falls-portfolio-three.jpg',
      alt: 'idaho-falls-portfolio',
    },
    {
      id: '04',
      src: '/idaho-falls-portfolio-four.jpg',
      alt: 'idaho-falls-portfolio',
    },
    {
      id: '05',
      src: '/idaho-falls-portfolio-five.jpg',
      alt: 'idaho-falls-portfolio',
    },
    // {
    //   id: '06',
    //   src: '/Rexburg_Alpine_Chalet_Interior.jpg',
    //   alt: 'idaho-falls-portfolio',
    // },
    // {
    //   id: '07',
    //   src: '/Rexburg_Rockland_Game_Room.jpg',
    //   alt: 'idaho-falls-portfolio',
    // },
    // {
    //   id: '08',
    //   src: '/Rexburg_Rockland_Interior.jpg',
    //   alt: 'idaho-falls-portfolio',
    // },
    // {
    //   id: '09',
    //   src: '/Rexburg_Somerset_Game_Room.jpg',
    //   alt: 'idaho-falls-portfolio',
    // },
    // {
    //   id: '10',
    //   src: '/Rexburg_Somerset_Interior.jpg',
    //   alt: 'idaho-falls-portfolio',
    // },
  ]