import { Box } from '@chakra-ui/react'
import React from 'react';
import NavBar from '../components/NavigationBar';
import { Hero } from '../components/Contact/Hero';
import Footer from '../components/Footer';
import TeamInfo from '../components/Contact/TeamInfo';
import { ContactForm } from '../components/Contact/ContactForm';

const Contact = () => {
  return (
    <Box maxWidth={"1250px"} mx={"auto"}  bgColor={'#ffffff'} >
    <NavBar />
    <Hero />
    <TeamInfo />
    <ContactForm />
    <Footer />
    </Box>
  );
};

export default Contact;