export const images = [
    {
      id: '01',
      src: '/Lofts-1.jpg',
      alt: 'Lofts on 34th',
    },
    // {
    //   id: '02',
    //   src: '/shulsen-lane-2.jpg',
    //   alt: 'Shulsen Lane',
    // },
    {
      id: '03',
      src: '/Lofts-2.jpg',
      alt: 'Lofts on 34th',
    },
    {
      id: '04',
      src: '/Lofts-3.jpg',
      alt: 'Lofts on 34th',
    },
    
    {
        id: '06',
        src: '/Lofts-4.jpg',
        alt: 'Lofts on 34th',
      },
      {
        id: '07',
        src: '/Lofts-5.jpg',
        alt: 'Lofts on 34th',
      },
      {
        id: '07',
        src: '/Lofts-6.jpg',
        alt: 'Lofts on 34th',
      },
    
    //   {
    //     id: '05',
    //     src: '/DesertSage (5).jpg',
    //     alt: 'Shulsen Lane',
    //   },
    // {
    //   id: '06',
    //   src: '/Alpine_Flats_Theater_Room.jpg',
    //   alt: 'Revive Apartments',
    // },
   
  ]