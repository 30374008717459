import { Box, Button, Center, Heading, LightMode, Flex, Image } from '@chakra-ui/react'

export const HomeAchievements = () => {
  return (
    <Box
      bg="rgba(34, 34, 34, 0.45)"
      marginTop={'30px'}
      py={["5","10","12"]}
      position="relative"
      h={{ base: '420px', md: '675px' }}
      bgImage="/saul-flores-KDm_2.png"
      bgSize="cover"
      bgPosition="center"
      
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: 'full',
        position: 'absolute',
        inset: 0,
        zIndex: 0,
      }}
    >
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '4', md: '8' }}
        h="full"
        maxHeight={'250px'}
        zIndex={1}
        position="relative"
      >
        <Center flexDirection="column" textAlign="center" color="white" h="full">
          <Heading fontFamily={'montserrat, sans-serif'} fontSize={['19px','25px','36px']} fontWeight="bold" pb={{ base: '8px', md: '25px' }} pt={{ base: '0px', md: '150px' }} letterSpacing={'1.8px'}>
            NOTABLE ACHIEVEMENTS
          </Heading>
          <Heading fontFamily={'Noto Sans, sans-serif'} fontWeight="medium" fontSize={{ base: 'sm', md: '24px'}} maxWidth={'80%'} color={'#EAE6DC'} lineHeight={['28px','35px','45px']}>
          The Shin-Zylstra team has been recognized year after year for their exceptional work in the multifamily market.
          </Heading>
          <ImageRow />
        </Center>
      </Box>
      <Box
        display={{ base: 'none', md: 'block' }}
        position="absolute"
        zIndex={2}
        w="full"
        bottom="0"
        py="4"
        
      >
      </Box>
    </Box>
  )
}

const ImageRow = () => {

    const imageNames = ['ImageOne.png', 'ImageTwo.png', 'ImageThree.png', 'ImageFour.png', 'ImageFive.png'];

    return (
      <Flex align="center" justify="center" pt={['10px','30px','30px']}>
         <Image  src={`/ImageOne.png`} alt={`Image`}  maxHeight={{ base: '34px', md: '69px' }} m={{ base: 2, md: 9 }} />
         <Image  src={`/ImageTwo.png`} alt={`Image`}  maxHeight={{ base: '34px', md: '67px' }} m={{ base: 2, md: 9 }} />
         <Image  src={`/ImageThree.png`} alt={`Image`}  maxHeight={{ base: '34px', md: '100px' }} m={{ base: 2, md: 9 }} />
         <Image  src={`/ImageFour.png`} alt={`Image`}  maxHeight={{ base: '16px', md: '32px' }} m={{ base: 2, md: 9 }} />
         <Image  src={`/ImageFive.png`} alt={`Image`}  maxHeight={{ base: '26px', md: '51px' }} m={{ base: 2, md: 9 }} />
      </Flex>
    );
  };
  