import { Box, Button, Stack, Heading, LightMode, SimpleGrid, Text } from '@chakra-ui/react'

export const Hero = () => {
  return (
    <Box
      as="section"
    //   bg="gray.800"
      py="12"
      position="relative"
      h={{ base: '500px', md: '600px' }}
      bgImage="/contact-hero.png"
      bgSize="cover"
      bgPosition="center"
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: 'full',
        // bg: 'blackAlpha.700',
        position: 'absolute',
        inset: 0,
        zIndex: 0,
      }}
    >
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
        h="full"
        zIndex={1}
        position="relative"
      >
        <Stack flexDirection="column" textAlign="left" color="white" h="full" pl={'5%'}pr={'5%'}>
          <Heading fontFamily={'montserrat, sans-serif'}  size={["l", "xl", "xl"]} fontWeight="extrabold" pt={['30%','25%','25%']}>
          Contact
          </Heading>
          <Heading fontFamily={'montserrat, sans-serif'} fontSize={["16px", "25px", "27px"]} fontWeight="light" pt={'2%'} lineHeight={['30px','35px','40px']}>
          Get in touch with Utah’s leading multifamily brokerage team to discuss opportunities or 
          ideas on your next commercial investment.
          </Heading>
        </Stack>
      </Box>
      <Box
        display={{ base: 'none', md: 'block' }}
        position="absolute"
        zIndex={2}
        w="full"
        bottom="0"
        py="4"
        
      >
      </Box>
    </Box>
  )
}