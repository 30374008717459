import React from "react";

import { Link, Box, Flex, Text, Stack, Grid, Image } from "@chakra-ui/react";


const NavBar = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavBarContainer {...props}>
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks isOpen={isOpen} />
    </NavBarContainer>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="black"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="black"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const Logo = () => {
    return (
      <Link href="/">
      <Image src="/SZ_Logo-01.png" alt="Logo" 
      pr={['0px','0px','0px']}
      minWidth={{ base: '158', md: '175' }}
      />
    </Link>
    )
    
    
  };

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}
     mb={{base: '-15'}}
     position={'relative'}
     zIndex={2}
    >
      {isOpen ? <CloseIcon /> : <MenuIcon />}
     
    </Box>
  );
};

const MenuItem = ({ children, isLast, to = "/", ...rest }) => {  
  return (
    <Link href={to}>
      <Text 
      display="block"  
      css={{
        color: 'var(--actionable-item, #7D837F)',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '16px', // Set the base font size
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: 'normal',
        letterSpacing: '-0.36px',
        '@media (min-width: 768px)': {
          fontSize: '19px', // Set the font size for 'md' breakpoint and up
        },
      }}
      
      {...rest}>
        {children}
      </Text>
    </Link>
  );
};

const MenuLinks = ({ isOpen }) => {
  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
      position={'relative'}
      zIndex={1}
    >
      <Stack
        spacing={{ base: 1, md: 8 }}
        align="center"
        justify={["flex-end", "flex-end", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        mt={[, 0, 0, 0]}
        pt='0'
      >
        <MenuItem 
        
        to="/investments"> Investments </MenuItem>
        <MenuItem 
        to="/about"> About </MenuItem>
        <MenuItem 
        to="/contact"> Contact </MenuItem>
      </Stack>
    </Box>
  );
};

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Grid borderBottom={{
        base: '9px solid #CBA689',
        md: '14px solid #CBA689',
      }}
    templateColumns={{ base: '1fr', base: '30% 70%' }}
    
    >
        <Flex
        align="center"
        
        justify="flex-start"
        wrap="wrap"
        // w="100%"
        maxWidth={{ base: '100%', md: '75%' }}
        mb={0}
        pl={[4,10,10]}
        bg={["transparent", "transparent", "transparent", "transparent"]}
        >  
        <Logo />
        </Flex>
       <Flex
      
      align="center"
      justify="flex-end"
      wrap="wrap"
      w="100%"
      mb={0}
        pr={10}
      bg={["transparent", "transparent", "transparent", "transparent"]}
      color={["black", "black", "black", "black"]}
      {...props}
    >
      {children}
    </Flex> 
    </Grid>
    
  );
};

export default NavBar;