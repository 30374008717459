export const products = [
  {
    id: '1',
    name: 'The Morton',
    title: 'CORE / CORE PLUS',
    imageUrl:
      './TheMorton(2).jpg',
    description:
      '137 Units - 2019 - 226,156 Sq Ft',
  },
  {
    id: '2',
    name: 'North Union',
    title: 'DEVELOPMENTS',
    imageUrl:
      './NorthUnion.jpg',
    description:
      '233 Units - 2022 - 199,700 Sq Ft',
  },
  {
    id: '3',
    name: 'The Ivy at Draper',
    title: 'SENIOR HOUSING',
    imageUrl:
      './TheIvy.png',
    description:
      '277 Units - 2015 - 252,594 Sq Ft',
    
  },
  {
    id: '4',
    name: 'College Place Provo',
    title: 'STUDENT HOUSING',
    imageUrl:
      './CollegePlace.jpg',
    description:
      '448 beds - 2012 | 2016 - 89,800 Sq Ft',
  },
  {
    id: '5',
    name: 'Cobble Creek',
    title: 'VALUE ADD',
    imageUrl:
      './CobbleCreek.jpg',
    description:
      '361 Units - 1973 - 309,719 Sq Ft',
  },
  {
    id: '6',
    name: 'Canyon Park',
    title: 'LIHTC',
    imageUrl:
      'CaynonPark.png',
    description:
      '192 Units - 2003 - 179,664 Sq Ft',
  },
]