import { Box } from '@chakra-ui/react'
import React from 'react';
import NavBar from '../components/NavigationBar';
import { HomeHero } from '../components/Home/HomeHero';
import { HomeTopContent } from '../components/Home/HomeTopContent';
import { InfoGrid } from '../components/Home/InfoGrid';
import { HomeAchievements } from '../components/Home/HomeAchievements';
import { HomeLowerContent } from '../components/Home/HomeLowerContent';
import { TrophyAssetsGrid } from '../components/Home/TrophyAssetsGrid/TrophyAssetsGrid';
import { PreFooter } from '../components/Home/PreFooter';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <>
    <Box maxWidth={"1250px"} mx={"auto"}  bgColor={'#ffffff'} >
        <NavBar />
        <HomeHero />
        <HomeTopContent />
        <InfoGrid />
        <HomeAchievements />
        <HomeLowerContent />
        <TrophyAssetsGrid />
        <PreFooter />
        <Footer />
    </Box>
    
    </>
    
  );
};

export default Home;