import {
    AspectRatio,
    Box,
    HStack,
    Image,
    Skeleton,
    Stack,
    Text,
    useColorModeValue,
  } from '@chakra-ui/react'
  
  export const ProductCard = (props) => {


    const { product, rootProps } = props
    const { name, imageUrl, description, title } = product
    return (
      <Stack
      gap={'0px'}
        spacing={{
          base: '4',
          md: '5',
        }}
        {...rootProps}
      >
        <Box position="relative">
        <Text fontSize={['16px','20px','23px']} fontFamily={'montserrat, sans-serif'} pb={'4px'} fontWeight="bold" color={useColorModeValue('gray.700', 'gray.400')} style={{ whiteSpace: 'nowrap' }}>
              {title}
            </Text>
          <AspectRatio ratio={4 / 3} height={['150px','180px','180px']}>
            <Image
              src={imageUrl}
              alt={name}
              draggable="false"
              fallback={<Skeleton />}
              borderRadius={{
                base: '0',
                md: '0',
              }}
            />
          </AspectRatio>
        </Box>
        <Stack height={['75px','90px','90px']} gap={'0px'} borderColor={useColorModeValue('gray.300', 'gray.600')} borderLeft={'1px'} borderRight={'1px'} borderBottom={'1px'}>
          <Stack >
            <Text style={{ whiteSpace: 'nowrap' }} fontSize={['14px','17px','19px']} fontFamily={'montserrat, sans-serif'} pt={'20px'} fontWeight="600" color={useColorModeValue('gray.700', 'gray.400')}>
              {name}
            </Text>
          </Stack>
          <HStack justifyContent="center" alignItems="center">
            <Text style={{ whiteSpace: 'nowrap' }} fontFamily={'montserrat, sans-serif'} pb={'10px'} fontSize={{base: '9px', md: 'sm' }} color={useColorModeValue('gray.600', 'gray.400')}>
              {description}
            </Text>
          </HStack>
        </Stack>
      </Stack>
    )
  }