export const images = [
    {
      id: '01',
      src: '/AVIA Founders_BBQ and Patio.jpg',
      alt: 'AVIA',
    },
    {
      id: '02',
      src: '/_AVIA Founders_Aerial.jpg',
      alt: 'AVIA',
    },
    {
      id: '03',
      src: '/_AVIA Founders_Exterior.jpg',
      alt: 'AVIA',
    },
    {
      id: '04',
      src: '/AVIA_Founders_Clubhouse.jpg',
      alt: 'AVIA',
    },
    {
      id: '05',
      src: '/AVIA_Founders_Fitness_Center.jpg',
      alt: 'AVIA',
    },
    {
      id: '06',
      src: '/AVIA_Founders_Interior.jpg',
      alt: 'AVIA',
    },
    
  ]
