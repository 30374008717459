import {
    Box,
    Button,
    Container,
    Heading,
    Image,
    Text,
    Grid,
    Flex,
    GridItem,
    Link
  } from '@chakra-ui/react'
  
  export const InfoGrid = () => (
    <Box paddingLeft={'7%'} pb={{base:'20px',md: '50px'}}>
      <Text
      paddingLeft={'15px'}
       fontFamily={'montserrat, sans-serif'} 
       fontWeight={'semibold'} 
       align={'left'} 
       mb={{ base: '0px', md: '-60px' }} 
       fontSize={{ base: '2xl', md: '36px' }} 
       letterSpacing={'1.8px'}
       > SERVICES</Text>
      <Container maxWidth={'unset'} paddingRight={0} paddingLeft={0}
      >
        <Flex
          direction={{
            base: 'column',
            md: 'row',
          }}
          align={{
            base: 'start',
            md: 'center',
          }}
        >
          
          <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={{ base: 1, md: 2 }} >
          <GridItem minWidth={'233px'}>
        <Box  p={4} align={'left'} >
          <Heading fontFamily={'montserrat, sans-serif'} as="h2" fontSize={'18px'} mb={4} color={'#475253'} minWidth={'185px'}>
          Property Transactions
          </Heading>
          <Text fontFamily={'Noto Sans, sans-serif'} mb={[6,2,2]} fontSize={{base:'16px',md: '14px'}} color={'#475253'} height={'63px'} paddingRight={'15px'}>
          Acquire and downsize assets from start to finish through our excellent marketing process.
          </Text>
          <Link href={"/about"}>
            <Button fontFamily={'montserrat, sans-serif'} bgColor={'#475253'} color={'#EAE6DC'} fontSize={'14px'} size={'sm'} _hover={{ backgroundColor: 'black' }}>Learn more</Button>
          </Link>
        </Box>
      </GridItem>
      <GridItem minWidth={'233px'}>
        <Box p={4} align={'left'}>
          <Heading fontFamily={'montserrat, sans-serif'} as="h2" fontSize={'18px'} mb={4} color={'#475253'}>
          Investment Advice
          </Heading>
          <Text fontFamily={'Noto Sans, sans-serif'} mb={2} fontSize={{base:'16px',md: '14px'}} color={'#475253'} height={'63px'} paddingRight={'45px'}>
          Come to the experts to understand the best financial decision for your portfolio.
          </Text>
          <Link href={"/about"}>
            <Button fontFamily={'montserrat, sans-serif'} bgColor={'#475253'} color={'#EAE6DC'} fontSize={'14px'} size={'sm'} _hover={{ backgroundColor: 'black' }}>Learn more</Button>
          </Link>
        </Box>
      </GridItem>
      <GridItem minWidth={'233px'}>
        <Box p={4} align={'left'}>
          <Heading fontFamily={'montserrat, sans-serif'} as="h2" fontSize={'18px'} mb={4} color={'#475253'}>
          Capital Placement
          </Heading>
          <Text fontFamily={'Noto Sans, sans-serif'}  mb={[6,2,2]} fontSize={{base:'16px',md: '14px'}} color={'#475253'} height={'63px'}  paddingRight={'15px'}>
          Reap the benefits of connecting with high-profile investors & developers across the nation.
          </Text>
          <Link href={"/about"}>
            <Button fontFamily={'montserrat, sans-serif'} bgColor={'#475253'} color={'#EAE6DC'} fontSize={'14px'} size={'sm'} _hover={{ backgroundColor: 'black' }}>Learn more</Button>
          </Link>
        </Box>
      </GridItem>
      <GridItem minWidth={'233px'}>
      <Box p={4} align={'left'}>
          <Heading fontFamily={'montserrat, sans-serif'} as="h2" fontSize={'18px'} mb={4} color={'#475253'}>
          Property Evaluations
          </Heading>
          <Text fontFamily={'Noto Sans, sans-serif'}  mb={[6,2,2]} fontSize={{base:'16px',md: '14px'}} color={'#475253'} height={'63px'}  paddingRight={'45px'}> 
          Understand the value of your property to help make educated investment decisions.
          </Text>
          <Link href={"/about"}>
            <Button fontFamily={'montserrat, sans-serif'} bgColor={'#475253'} color={'#EAE6DC'} fontSize={'14px'} size={'sm'} _hover={{ backgroundColor: 'black' }}>Learn more</Button>
          </Link>
        </Box>
      </GridItem>
    </Grid>


          <Box
            w={'600px'}
            h={'590px'}
            
            // height={{
            //   base: 'md',
            //   md: '500px',
            // }}
            display={["none", "block", "block"]}
            css={{
              display: {
                base: 'none',  // Hide for mobile devices
                md: 'block',   // Show for larger screens (md and up)
              },
            }}
          >
            <Image
              boxSize="full"
              alt="Subscribe to newsletter image"
              src="/TheMorton.jpg"
              objectFit="cover"
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  )