import React, { useState } from 'react';
import { Flex, Image, Text, Input, InputGroup, InputRightElement, Button, useToast } from '@chakra-ui/react';
import emailjs from '@emailjs/browser';

const Footer = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const toast = useToast();

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Check if name and email are not empty
    if (name.trim() === '' || email.trim() === '') {
      alert('Name and Email are required.');
      return;
    }

    // Replace these values with your actual EmailJS template ID and service ID
    const templateId = 'template_nighy6c';
    const serviceId = 'service_y3qhbw9';

    // Replace with your EmailJS user ID
    const userId = 'user_kfNlnbqByB7DlHcb0UF26';

    // Prepare the template parameters
    const templateParams = {
      name: name,
      email: email,
    };

    // Send the email using EmailJS
    emailjs.send(serviceId, templateId, templateParams, userId)
    .then((response) => {
      console.log('Email sent successfully:', response);
      toast({
        title: 'Success!',
        description: 'You have successfully joined the mailer.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      // Clear the form
      setName('');
      setEmail('');
    })
    .catch((error) => {
      console.error('Email failed to send:', error);
      // You can add error handling here, like showing an error message to the user
    });
};

  return (
    <Flex
      direction={['column', 'column', 'row']}
      align={['center', 'center', 'center']}
      justify={['center', 'center', 'space-between']}
      py={[0, 8, 8]}
      px={[0, 8, 8]}
    >
      {/* Left side (Logo and Text) */}
      <Flex paddingLeft={['0%', '0%', '2%']} direction="column" align={['center', 'center', 'flex-start']} mb={['-32px', '0px', '0px']}>
        <Image src="../SZ_Logo-01.png" alt="Logo" height={['100px', '100px', '180px']} paddingLeft={'25px'} paddingRight={['30px', 0, 0]} />
        <Text display={['none', 'none', 'block']} lineHeight={'25px'} textAlign={'left'} paddingLeft={['15px', '50px', '45px']} fontFamily={'Noto Sans, sans-serif'} mt="-35px" pb={['0px', '0px', '25px']} maxWidth={['80%', '70%', '410px']} fontSize="sm">The number one choice in investments and evaluations, rising above the rest with consistency, professionalism and client relationships.</Text>
      </Flex>

      {/* Right side (Title, Text, and Input fields) */}
      <Flex
        paddingLeft={['5px', '20px', '5px']}
        direction="column"
        align={['center', 'center', 'right']}
        maxWidth={['80%', '70%', '55%']}
        paddingRight={['0px', '0px', '35px']}
        marginTop={['20px', '0px', '50px']}
      >
        <Text fontFamily={'montserrat, sans-serif'} fontSize={['14px', '16px', '20px']} fontWeight={'700'} >Never Miss an Opportunity to Invest in Something Great</Text>
        <Text
          textAlign={['center', 'right', 'right']}
          fontFamily={'Noto Sans, sans-serif'}
          fontSize="sm"
          mt={{ base: '10px', sm: '5px' }}
          pl={['5px', 0, '20px']}
          mb={2}
          lineHeight={['22px', '25px', '25px']}
          paddingRight={'15px'}
        >Get on our list! Sign up for exclusive listing announcements, Shin-Zylstra events, and market trends in the Utah region.
        </Text>
        <Flex direction={['row', 'row', 'row']} mb={4} width="100%" maxWidth="600px" pt={{ base: '5px', sm: '10px' }}>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            fontFamily={'Noto Sans, sans-serif'}
            bg={'#D9D9D9'}
            type="Text"
            placeholder="First and Last Name"
            mr={[0, 0, 2]}
            mb={[2, 2, 0]}
            size={["sm", "sm", "sm"]}
            fontSize={['10px', '10px', '']}
          />
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fontFamily={'Noto Sans, sans-serif'}
            type="email"
            placeholder="Email Address"
            mr={[0, 0, 2]}
            mb={[2, 2, 0]}
            size={["sm", "sm", "sm"]}
            bg={'#D9D9D9'}
            marginLeft={'25px'}
            fontSize={['10px', '10px', '']}
          />
          <InputGroup maxWidth={["45px", "75px", "75px"]}>
            <InputRightElement>
              <Button
                onClick={handleFormSubmit}
                bg={"white"}
                _hover={{ bg: 'white' }}
              >
                <Image src="../Send.svg" alt="Logo" maxWidth={["38px", "72px", "72px"]} />
              </Button>
            </InputRightElement>
          </InputGroup>
        </Flex>
        <Text paddingBottom={[0, 0, 0]} paddingLeft={['56%', '74%', '80%']} fontFamily={'montserrat, sans-serif'} fontSize={'10px'}>© Shin-Zylstra. 2023</Text>
      </Flex>
    </Flex>
  );
};

export default Footer;
