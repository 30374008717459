export const images = [
    {
      id: '01',
      src: '/The Linq_02.jpg',
      alt: 'The Linq',
    },
    // {
    //   id: '02',
    //   src: '/shulsen-lane-2.jpg',
    //   alt: 'Shulsen Lane',
    // },
    {
      id: '03',
      src: '/The Linq_Bathroom_Furnished-2.jpg',
      alt: 'The Linq',
    },
    {
      id: '04',
      src: '/The Linq_Bedroom_Furnished-1.jpg',
      alt: 'The Linq',
    },
    
    {
        id: '06',
        src: '/The Linq_Entry Way_Furnished-2.jpg',
        alt: 'The Linq',
      },
      {
        id: '07',
        src: '/The Linq_Kitchen_Furnished-3.jpg',
        alt: 'The Linq',
      },
    //   {
    //     id: '05',
    //     src: '/DesertSage (5).jpg',
    //     alt: 'Shulsen Lane',
    //   },
    // {
    //   id: '06',
    //   src: '/Alpine_Flats_Theater_Room.jpg',
    //   alt: 'Revive Apartments',
    // },
   
  ]