export const images = [
    {
      id: '01',
      src: '/DesertSage.jpg',
      alt: 'Desert Sage',
    },
    {
      id: '02',
      src: '/DesertSage (2).jpg',
      alt: 'Desert Sage',
    },
    // {
    //   id: '03',
    //   src: '/DesertSage (8).jpg',
    //   alt: 'Desert Sage',
    // },
    {
      id: '04',
      src: '/DesertSage (4).jpg',
      alt: 'Desert Sage',
    },
    
    {
        id: '06',
        src: '/DesertSage (6).jpg',
        alt: 'Desert Sage',
      },
      {
        id: '07',
        src: '/DesertSage (7).jpg',
        alt: 'Desert Sage',
      },
      {
        id: '05',
        src: '/DesertSage (5).jpg',
        alt: 'Desert Sage',
      },
    // {
    //   id: '06',
    //   src: '/Alpine_Flats_Theater_Room.jpg',
    //   alt: 'Revive Apartments',
    // },
   
  ]