import { Box } from '@chakra-ui/react'
import React from 'react';
import NavBar from '../components/NavigationBar';
import { Hero } from '../components/About/Hero'
import Footer from '../components/Footer';
import { AboutTopContent } from '../components/About/AboutTopContent'
import  Services  from '../components/About/Services'
import Team from '../components/About/Team'
import SalesTeam from '../components/About/SalesTeam';
import MarketingTeam from '../components/About/MarketingTeam';

const About = () => {
  return (
    <Box maxWidth={"1250px"} mx={"auto"}  bgColor={'#ffffff'} >
    <NavBar />
    <Hero />
    <AboutTopContent />
    <Box  id='services'></Box>
    <Services />
    <Team />
    {/* <SalesTeam /> */}
    <MarketingTeam />
    <Footer />
    </Box>
  );
};

export default About;
