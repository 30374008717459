import React from 'react';
import {  Routes, Route } from 'react-router-dom';
import {
  ChakraProvider,
  Box,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import customTheme from "./utils/themes";
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Home from './pages/Home';
import About from './pages/About';
import Investments from './pages/Investments'
import Contact from './pages/Contact'
import IdahoFallsPortfolio from './pages/Listings/idaho-falls-portfolio-private-active'
import RexburgPortfolio from './pages/Listings/RexburgPortfolio';
import Avia from './pages/Listings/Avia';
import AlpineFlats from './pages/Listings/alpine-flats';
import ClosedFallsAtCanyonRim from './pages/Listings/closed-falls-at-canyon-rim';
import ParkStation from './pages/Listings/closed-park-station';
import CanyonPark from './pages/Listings/closed-canyon-park';
import TuscanyCove from './pages/Listings/closed-tuscany-cove';
import LakesideVillage from './pages/Listings/closed-lakeside-village';
import IvyAtDraper from './pages/Listings/closed-the-ivy-at-draper';
import NorthUnion from './pages/Listings/closed-north-union';
import HiddenVillas from './pages/Listings/hidden-villas';
import MurrayParkView from './pages/Listings/closed-murray-park-view';
import ModaSpringsRun from './pages/Listings/closed-moda-spring-run';
import VillasAtFernCircle from './pages/Listings/closed-villas-at-fern-circle';
import ClosedCrossroadApartments from './pages/Listings/closed-crossroads-apartments'
import ClosedEaglesLanding from './pages/Listings/closed-eagles-landing';
import ClosedRemingtonApartments from './pages/Listings/closed-remington-apartments';
import DesertSageTownhomes from './pages/Listings/desert-sage-townhomes';
import ShulsenLane from './pages/Listings/closed-shulsen-lane';
import TheLinq from './pages/Listings/the-linq';
import Annie from './pages/Listings/annie';
import Ashli from './pages/Listings/ashli';
import TheGrand from './pages/Listings/the-grand'
import LoftsOn34 from './pages/Listings/lofts-on-34';
import MtEyrie from './pages/Listings/mt-eyrie';
import ClosedRevive from './pages/Listings/closed-revive';
import AtHome from './pages/Listings/at-home-rexburg';
import ClosedMonaco from './pages/Listings/closed-monaco';
import CollegeWay from './pages/Listings/college-way';
import TwinPines from './pages/Listings/twin-pines';
function App() {

  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <ChakraProvider theme={customTheme}>
      <Box textAlign="center" fontSize="xl" bgColor={'#dfdfdf'}>

        <ScrollToTop/>
      <Routes>
        {/* Define the routes for each page */}
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/investments" element={<Investments />} />
        <Route path="/contact" element={<Contact />} />
        {/* Active Listing - Institutional */}
        <Route path="/lofts-at-founders-road" element={<Avia />} />
        <Route path="/rexburg-portfolio" element={<RexburgPortfolio />} />
        {/* <Route path="/brookfield-apartments" element={<Brookfield />} /> */}
        {/* Active Listing - Private */}
        <Route path="/twin-pines" element={<TwinPines />} />
        <Route path="/college-way-stadium-way" element={<CollegeWay />} />
        <Route path="/the-grand-portfolio" element={<TheGrand />} />
        <Route path="/mt-eyrie" element={<MtEyrie />} />
        <Route path="/at-home-rexburg" element={<AtHome />} />
        <Route path="/lofts-on-34th" element={<LoftsOn34 />} />
        <Route path="/the-ashli-portfolio" element={<Ashli />} />
        <Route path="/the-annie" element={<Annie />} />
        <Route path="/the-linq" element={<TheLinq />} />
        <Route path="/desert-sage-townhomes" element={<DesertSageTownhomes />} />
        <Route path="/hidden-villas" element={<HiddenVillas />} />
        <Route path="/alpine-flats" element={<AlpineFlats />} />
        <Route path="/idaho-falls-portfolio" element={<IdahoFallsPortfolio />} />
        {/* Closed Listing */}
        <Route path="/closed-revive" element={<ClosedRevive />} />
        <Route path="/closed-remington-apartments" element={<ClosedRemingtonApartments />} />
        <Route path="/closed-shulsen-lane" element={<ShulsenLane />} />
        <Route path="/closed-eagles-landing" element={<ClosedEaglesLanding />} />
        <Route path="/closed-falls-at-canyon-rim" element={<ClosedFallsAtCanyonRim />} />
        <Route path="/closed-crossroads-apartments" element={<ClosedCrossroadApartments />} />
        <Route path="/closed-villas-at-fern-circle" element={<VillasAtFernCircle />} />
        <Route path="/closed-moda-spring-run" element={<ModaSpringsRun />} />
        <Route path="/closed-murray-park-view" element={<MurrayParkView />} />
        <Route path="/closed-park-station" element={<ParkStation />} />
        <Route path="/closed-canyon-park" element={<CanyonPark />} />
        <Route path="/closed-tuscany-cove" element={<TuscanyCove />} />
        <Route path="/closed-lakeside-village" element={<LakesideVillage />} />
        <Route path="/closed-ivy-at-draper" element={<IvyAtDraper />} />
        <Route path="/closed-north-union" element={<NorthUnion />} />
        <Route path="/closed-monaco" element={<ClosedMonaco />} />
        {/* Add a "Not Found" route in case the user visits an unknown URL */}
        <Route path="*" component={() => <div>Not Found</div>} />
      </Routes>

      </Box>
    </ChakraProvider>
  );
}

export default App;
