import { Box, Text, Flex, Image, Button } from '@chakra-ui/react'
import React from 'react';
import NavBar from '../../components/NavigationBar'
import Footer from '../../components/Footer';
import TeamDisplay from '../../components/Contact/TeamDisplay';
import { Link } from 'react-router-dom';
import { Gallery } from '../../components/Gallery/Gallery';
import { images } from './idaho-falls-portfolio-data'
import CardMapper from './../../components/FloorPlanCards/CardMapper';
const IdahoFallsPortfolio = () => {

     // Example data array
  const data = [
    { title: 'Studio', bedBath: '0 Bed, 1 Bath', sqFt: '±483 Sq. Ft.', price: '$903+' },
    { title: '1 Bed, 1 Bath', bedBath: '1 Bed, 1 Bath', sqFt: '±480 Sq. Ft.', price: '$997+' },
    { title: '1 Bed, 1 Bath L', bedBath: '1 Bed, 1 Bath', sqFt: '±534 Sq. Ft.', price: '$989+' },
    { title: '2 Bed, 2 Bath', bedBath: '2 Bed, 1 Bath', sqFt: '±750 Sq. Ft.', price: '$1,310+' },
    
    // Additional data items
    // ...
  ];




  return (
    <Box maxWidth={"1250px"} mx={"auto"}  bgColor={'#ffffff'} >
    <NavBar />
    
    <Box
      as="section"
    //   bg="gray.800"
      py="12"
      position="relative"
      h={{ base: '217px', md: '572px' }}
      bgImage="/idaho-falls-portfolio-hero.jpg"
      bgSize="cover"
      bgPosition="center"
    >
      
      <Button 
     as="a"
     href="https://www.crexi.com/properties/1147760/idaho-idaho-falls-multifamily-portfolio"
     target="_blank"
     rel="noopener noreferrer"
      zIndex={'1'}
      marginTop={['215px','200px','420px']}
      letterSpacing={'1px'}
      fontSize={['14px','16px','18px']}
      height={['50px','50px','50px']}
      width={['200px','200px','200px']}
      marginLeft={['0px','0px','63%']}
      backgroundColor={'#CBA689'}
      color={'#FFFFFF'}
      fontFamily={'montserrat, sans-serif'}
      _hover={{ backgroundColor: '#a2765e' }}
      >View Listing</Button>
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
        h="full"
        zIndex={1}
        position="relative"
      >
      </Box>
      <Box
        display={{ base: 'none', md: 'block' }}
        position="absolute"
        zIndex={2}
        w="full"
        bottom="0"
        py="4"
        
      >
      </Box>
     
        <Text
          paddingTop={'3px'}
          paddingLeft={'10px'}
          style={{ position: 'absolute', bottom: '0', left: '0', right: '0' }}
          bg="gray.600"
          color="#F2F2F2"
          opacity={'.7'}
          height={['28%','30%','25%']}
          pl={'20%'}
          textAlign="left"
          fontFamily={'montserrat, sans-serif'}
        >
        
        </Text>
    </Box>

    <Text
    fontSize={['16px','16px','30px']}
    fontFamily={'montserrat, sans-serif'}
    align={['center','left','left']}
    pl={['0%','10%','10%']}
    position="relative"
  
         mt={['-55px','-59px','-120px']}
         color={'#F2F2F2'}
         fontWeight={'bold'}
         >Idaho Falls Portfolio</Text>
         <Text
         fontSize={['13px','16px','22px']}
         fontFamily={'montserrat, sans-serif'}
         align={['center','left','left']}
        pl={['0%','10%','10%']}
         color={'#F2F2F2'}
         position="relative"
         mb={['100px','0px','100px']}
         >
         Idaho Falls, ID</Text>



    <Box
    as="section"
    //   bg="gray.800"
      py="12"
      position="relative"
      marginTop={['165px','130px','75px']}
      marginBottom={'75px'}
      marginLeft={'10%'}
      marginRight={'10%'}
      h={['725px','475px','475px']}
      bgColor={'#EAE6DC'}
      overflow={'auto'}
    >
    {/* Title */}
    <Text fontFamily={'montserrat, sans-serif'} fontSize={['20px','23px','32px']} fontWeight="bold" textAlign="center" mb="4" color={'#475253'}>
        THE OFFERING
      </Text>

      {/* Two Sections (60/40 Split) */}
      <Flex justifyContent="space-between" marginTop={'30px'} flexDirection={['column','row','row']}>
        <Box flex="3">
          {/* Paragraph */}
          <Text fontFamily={'montserrat, sans-serif'} fontSize={["15px","16px","17px"]} lineHeight={["22px","25px","27px"]} mb="4" marginLeft={'10%'} marginRight={'10%'} align={'left'} >
          Marcus and Millichap is pleased to present Alpine Flats, an established student housing development within walking distance of Utah State University. This opportunity offers university students both private and shared housing options in a variety of spacious floor plans. Additionally, students enjoy luxury amenities such as a community game room, outdoor recreation space, and a theatre room. Investors have the opportunity to capitalize on these premium features through higher than average rental rates.
          </Text>
          
        </Box>
        <Box flex="2">
          {/* Infographic */}
 <Box maxW="400px" marginRight={['25px','50px','50px']} marginLeft={['25px','0px','0px']} fontFamily={'montserrat, sans-serif'} marginTop={['25px','0px','0px']}>
      {/** Property Type */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb="2">
        <Text fontSize={["16px","16px","18px"]} fontWeight="bold">Property Type</Text>
        <Text fontSize={["16px","16px","18px"]}>Value Add</Text>
      </Box>
      <Box borderBottom="1px solid" borderColor={'#CBA689'} mb="2"></Box>

      {/** Construction */}
      {/* <Box display="flex" alignItems="center" justifyContent="space-between" mb="2">
        <Text fontSize={["16px","16px","18px"]} fontWeight="bold">Year Renovated</Text>
        <Text fontSize={["16px","16px","18px"]}>X</Text>
      </Box>
      <Box borderBottom="1px solid" mb="2" borderColor={'#CBA689'}></Box> */}

      {/** Number of Units */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb="2">
        <Text fontSize={["16px","16px","18px"]} fontWeight="bold">Number of Units</Text>
        <Text fontSize={["16px","16px","18px"]}>45</Text>
      </Box>
      <Box borderBottom="1px solid" mb="2" borderColor={'#CBA689'}></Box>

      {/** Year Completed */}
      {/* <Box display="flex" alignItems="center" justifyContent="space-between" mb="2">
        <Text fontSize={["16px","16px","18px"]} fontWeight="bold">Year Completed</Text>
        <Text fontSize={["16px","16px","18px"]}>X</Text>
      </Box>
      <Box borderBottom="1px solid" mb="2" borderColor={'#CBA689'}></Box> */}

      {/** Average Unit Size */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb="2">
        <Text fontSize={["16px","18px","19px"]} fontWeight="bold">Average Unit Size</Text>
        <Text fontSize={["16px","18px","19px"]}>645 SF</Text>
      </Box>
      <Box borderBottom="1px solid" mb="2" borderColor={'#CBA689'}></Box>

      {/** Parcel Size */}
      {/* <Box display="flex" alignItems="center" justifyContent="space-between">
        <Text fontSize={["16px","18px","19px"]} fontWeight="bold">Parcel Size</Text>
        <Text fontSize={["16px","18px","19px"]}>X</Text>
      </Box> */}
    </Box>
        </Box>
      </Flex>
    </Box>
    <Box p="4">


      {/* INVESTMENT HILIGHTS */}


      <Text fontFamily={'montserrat, sans-serif'} fontSize={['20px','23px','32px']} fontWeight="bold" textAlign="center" mb="4" color={'#475253'}>
        INVESTMENT HIGHLIGHTS
      </Text>

      {/* Three Sections (Even Split) */}
      <Flex justifyContent="space-evenly" fontSize={['15px','18px','18px']} flexDirection={['column','column','row']}>
        {/* Section 1 */}
        <Box flex="1" p="4" maxW={['96%','93%','27%']} marginLeft={['0px','0px','50px']}>
          <Text fontFamily={'Noto Sans, sans-serif'}>
          <span style={{ color: '#CBA689', fontWeight: 'bold' }}  >Going-In Cap Rate of 6.84%</span> - Portfolio holds a going-in cap rate of 6.84% with upside, providing attractive day-one yield for investors.
          </Text>
        </Box>

        {/* Section 2 */}
        <Box flex="1" p="4" maxW={['96%','93%','27%']}>
          <Text fontFamily={'Noto Sans, sans-serif'}>
          <span style={{ color: '#CBA689', fontWeight: 'bold' }}>Value Add Portfolio</span> - This portfolio gives investors the opportunity to acquire 45 units across 3 proximal properties - each has unique value-add opportunites and offers rental increases through cosmetic renovations.
          </Text>
        </Box>

        {/* Section 3 */}
        <Box flex="1" p="4" maxW={['96%','93%','27%']}  marginRight={['0px','0px','50px']}>
          <Text fontFamily={'Noto Sans, sans-serif'}>
            <span style={{ color: '#CBA689', fontWeight: 'bold' }}>Prime Location</span> - The opportunity benefits from its Idaho Falls location, where in 2021, the city was named the US best performing small city by the Milken Institute.
          </Text>
         
        </Box>
      </Flex>
    </Box>
    <Box>
        {/* Title */}
      <Text fontFamily={'montserrat, sans-serif'} fontSize={['20px','23px','32px']} fontWeight="bold" textAlign="center"  paddingTop={'75px'} color={'#475253'}>
        GALLERY & AMENITIES
      </Text>
      <Box
      mb={'35px'}
      maxW="80%"
      mx="auto"
      px={{
        base: '4',
        md: '8',
        lg: '12',
      }}
      py={{
        base: '6',
        md: '8',
        lg: '12',
      }}
    >
      <Gallery images={images}  />
    </Box>
      {/* <Box
      py="12"
      position="relative"
      marginTop={'55px'}
      marginBottom={'25px'}
      marginLeft={'10%'}
      marginRight={'10%'}
      h={{ base: '181px', md: '585px' }}
      bgImage="/brookfield-gallery.png"
      bgSize="cover"
      bgPosition="center"
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: 'full',
        // bg: 'blackAlpha.700',
        position: 'absolute',
        inset: 0,
        zIndex: 0,
      }}
      >
      </Box> */}
    </Box>
    <Box marginBottom={'50px'}>
          <Text
          fontFamily={'montserrat, sans-serif'}
          marginLeft={'10%'}
          marginRight={'10%'}
          align={'left'}
          fontSize={['15px','16px','16px']}
          lineHeight={['22px','24px','28px']}
          >
         
         The Idaho Falls Portfolio holds a unique advantage as an asset with multiple properties with varying
value-add statuses. With the mix of its competitive cap rate and easy renovations, potential buyers of
the portfolio will rapidly return on this investment through increased rental rates. Additionally, Idaho
Falls proves year after year to be a small town full of opportunity and growth. As the area continues to
see economic development, demand for housing wil stay consistent resulting in regular rental income.
          </Text>
          {/* <Text 
          pt={['15px','24px','28px']}
          fontFamily={'montserrat, sans-serif'}
          marginLeft={'10%'}
          marginRight={'10%'}
          align={'left'}
          fontSize={['15px','16px','16px']}
          lineHeight={['22px','24px','28px']}
          >
          Student housing investments are a solid staple of any multifamily portfolio with its reliable tenant pool from 
          its nearby school. Utah State University is in constant demand for student housing as the university grows, 
          resulting in promising lease-up numbers for the property. Alpine Flat's location also increases its value due 
          to its proximity to campus, the university shuttle, and the Cache Valley transit bus stops.
          </Text> */}
        </Box>


 {/* Title */}
 {/* <Text  display={['none','none','block']} fontFamily={'montserrat, sans-serif'} fontSize={['26px','30px','32px']} fontWeight="bold" textAlign="center"  paddingTop={'40px'} color={'#475253'} >
        FLOOR PLANS
      </Text>
     <CardMapper data={data}/> */}


{/* Title */}
{/* <Text display={['none','none','block']} fontFamily={'montserrat, sans-serif'} fontSize={['26px','30px','32px']} fontWeight="bold" textAlign="center"  paddingTop={'80px'} color={'#475253'} marginBottom={'20px'}>
LOCATION DEMOGRAPHICS
      </Text> */}
      {/* Title */}
 {/* <Text display={['none','none','block']} fontFamily={'montserrat, sans-serif'} fontSize={['26px','30px','30px']} fontWeight="normal" textAlign="center"  color={'#475253'} marginBottom={'50px'}>
 Indianapolis, Indiana
      </Text>
<Flex display={['none','none','flex']} justifyContent={'space-evenly'} >
   <Image  src={"/AVIA_Aerial.jpg"} width={'887px'}  height={'315px'} objectFit="cover"></Image>
</Flex> */}
     
{/* LOCATION DEMOGRAPHICS STATS UNDER PICTURE */}
{/* <Flex display={['none','none','flex']} justifyContent={'space-evenly'} marginTop={'35px'} fontFamily={'Noto Sans, sans-serif'}>
  <Box>
<Text color={'#CBA689'} fontWeight={'bold'}>Household Income</Text>
<Text marginTop={'5px'}>$XX,XXX</Text>
  </Box>
  <Box>
<Text color={'#CBA689'} fontWeight={'bold'}>Population</Text>
<Text marginTop={'5px'}>$XX,XXX</Text>
  </Box>
  <Box>
  <Text color={'#CBA689'} fontWeight={'bold'}>Bachelor’s Degree %</Text>
  <Text marginTop={'5px'} >$XX,XXX</Text>
  </Box>
</Flex> */}


    {/*ADVISORY TEAM */}
        <Box
        as="section"
        //   bg="gray.800"
          marginTop={'55px'}
          py="12"
          position="relative"
          h={{ base: '475px', md: '330px' }}
          bgImage="/listing-team-background.png"
          bgSize="cover"
          bgPosition="center"
          bgColor={'#EAE6DC'}
          _after={{
            content: `""`,
            display: 'block',
            w: 'full',
            h: 'full',
            // bg: 'blackAlpha.700',
            position: 'absolute',
            inset: 0,
            zIndex: 0,
            
          }}
        >
            
      <Text fontFamily={'montserrat, sans-serif'} fontSize={['20px','23px','32px']} fontWeight="bold" textAlign="center" mb="4">
        ADVISORY TEAM
      </Text>
      {/* Sub Title */}
      <Text fontFamily={'Noto Sans, sans-serif'} fontSize={["","2xl","2xl"]} fontWeight="bold" textAlign="center" mb="4">
      Marcus & Millichap
      </Text>
      <Flex flexDirection={['column','column','row']} justifyContent={'center'} mt={'1px'} alignItems={'center'}> 
      {/* First Employee */}
      <TeamDisplay
        imageSrc="/jake-miles-circle.png"
        name="Jake Miles"
        title="Senior Associate"
        number="801.736.2654"
        email="jake.miles@marcusmillichap.com"
        license="UT 11449836-SA00, ID: SP53647"
      />

      {/* Second Employee */}
      {/* <TeamDisplay
        imageSrc="/brock-zylstra-circle.png" // Add the image source for the second employee
        name="Brock Zylstra"
        title="Senior Vice President Investments"
        number="801.736.2642"
        email="bzylstra@ipausa.com"
        license="UT 8726586-SA00"
      /> */}
    </Flex>
        </Box>
    <Footer />
    </Box>
  );
};

export default IdahoFallsPortfolio;