import { Box } from '@chakra-ui/react'
import { NavTabs } from './NavTabs'

export const InvestmentContainer = () => (
  <Box
    maxW="7xl"
    mx="auto"
    px={{
      base: '1',
      md: '8',
      lg: '12',
    }}
    py={{
      base: '2',
      md: '8',
      lg: '12',
    }}
  >
    <NavTabs />
  </Box>
)