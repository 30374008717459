export const images = [
    {
      id: '01',
      src: '/TP-01.jpg',
      alt: 'Lofts on 34th',
    },
    // {
    //   id: '02',
    //   src: '/shulsen-lane-2.jpg',
    //   alt: 'Shulsen Lane',
    // },
    {
      id: '03',
      src: '/TP-02.jpg',
      alt: 'Lofts on 34th',
    },
    {
      id: '04',
      src: '/TP-03.jpg',
      alt: 'Lofts on 34th',
    },
    
    {
        id: '06',
        src: '/TP-04.jpg',
        alt: 'Lofts on 34th',
      },
      {
        id: '07',
        src: '/TP-05.jpg',
        alt: 'Lofts on 34th',
      },
      {
        id: '07',
        src: '/TP-06.jpg',
        alt: 'Lofts on 34th',
      },
      {
        id: '05',
        src: '/TP-07.jpg',
        alt: 'Shulsen Lane',
      },
    // {
    //   id: '06',
    //   src: '/Alpine_Flats_Theater_Room.jpg',
    //   alt: 'Revive Apartments',
    // },
   
  ]