import { Box } from '@chakra-ui/react'
import React from 'react';
import NavBar from '../components/NavigationBar';
import { Hero } from '../components/Investments/Hero'
import Footer from '../components/Footer';
import { InvestmentContainer } from '../components/Investments/InvestmentGrid/InvestmentContainer';

const Investments = () => {
  return (
    <Box maxWidth={"1250px"} mx={"auto"}  bgColor={'#ffffff'} >
    <NavBar />
    <Hero />
    <InvestmentContainer />
    <Footer />
    </Box>
  );
};

export default Investments;