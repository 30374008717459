import { Box, Button, Stack, FormControl, FormLabel, Input, Textarea, useToast } from '@chakra-ui/react';
import emailjs from '@emailjs/browser';

export const ContactForm = (props) => {
  const toast = useToast();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Replace these values with your actual EmailJS template ID and service ID
    const templateId = 'template_wm5sm2j';
    const serviceId = 'service_y3qhbw9';

    // Replace with your EmailJS user ID
    const userId = 'user_kfNlnbqByB7DlHcb0UF26';

    // You can customize the form data as needed
    const formData = new FormData(e.target);

    // Prepare the template parameters
    const templateParams = {
      name: `${formData.get('firstName')} ${formData.get('lastName')}`,
      email: formData.get('email'),
      phoneNumber: formData.get('phoneNumber'),
      organizationName: formData.get('organizationName'),
      organizationLocation: formData.get('organizationLocation'),
      reason: formData.get('reason'),
      bio: formData.get('bio'),
    };

    try {
      // Send the email using EmailJS
      await emailjs.send(serviceId, templateId, templateParams, userId);
      console.log(templateParams)
      // Display success toast and clear the form
      toast({
        title: 'Success!',
        description: 'Your form has been submitted.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      e.target.reset();
    } catch (error) {
      console.error('Email failed to send:', error);
      // Display error toast
      toast({
        title: 'Error!',
        description: 'There was an error submitting your form. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box as="form" bg="bg.surface" {...props} onSubmit={handleFormSubmit}>
      <Stack
        spacing="5"
        px={{
          base: '9%',
          md: '12%',
        }}
        py={{
          base: '5',
          md: '6',
        }}
      >
        <Stack
          spacing={["0","6","6"]}
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <FormControl id="firstName">
            <FormLabel fontWeight={'bold'} fontFamily={'montserrat, sans-serif'}>Name</FormLabel>
            <Input placeholder="First" backgroundColor={'#F2F2F2'} name="firstName" />
          </FormControl>
          <FormControl id="lastName">
            <FormLabel><br></br></FormLabel>
            <Input placeholder="Last" backgroundColor={'#F2F2F2'} name="lastName" />
          </FormControl>
        </Stack>
        <Stack
          spacing={["0","6","6"]}
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <FormControl id="email" >
            <FormLabel fontWeight={'bold'} fontFamily={'montserrat, sans-serif'}>Contact Information</FormLabel>
            <Input placeholder="Email Address" backgroundColor={'#F2F2F2'} name="email" />
          </FormControl>
          <FormControl id="phoneNumber">
            <FormLabel><br></br></FormLabel>
            <Input placeholder="Phone Number" backgroundColor={'#F2F2F2'} name="phoneNumber" />
          </FormControl>
        </Stack>
        <Stack
          spacing={["0","6","6"]}
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <FormControl id="organizationName">
            <FormLabel fontWeight={'bold'} fontFamily={'montserrat, sans-serif'}>Organization</FormLabel>
            <Input placeholder="Name of Representing Company" backgroundColor={'#F2F2F2'} name="organizationName" />
          </FormControl>
          <FormControl id="organizationLocation">
            <FormLabel><br></br></FormLabel>
            <Input placeholder="Division / City / State" backgroundColor={'#F2F2F2'} name="organizationLocation" />
          </FormControl>
        </Stack>
        <FormControl id="reason">
          <FormLabel fontWeight={'bold'} fontFamily={'montserrat, sans-serif'}>Reason for Inquiry</FormLabel>
          <Input placeholder="Subject" backgroundColor={'#F2F2F2'} name="reason" />
        </FormControl>
        <FormControl id="bio">
          <Textarea mt={['10px','0px','0px']} rows={3} resize="none" placeholder="Write your message here...." backgroundColor={'#F2F2F2'} name="bio" />
        </FormControl>
        <Button
          width="200px"
          height="50px"
          color="#FFFFFF"
          backgroundColor="#CBA689"
          fontFamily="montserrat, sans-serif"
          type="submit"
          fontSize={["16px", "18px", "19px"]}
          marginTop={["16px", "25px", "50px"]}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  );
};