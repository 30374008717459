export const images = [
    {
      id: '01',
      src: '/cw01.jpg',
      alt: 'Lofts on 34th',
    },
    // {
    //   id: '02',
    //   src: '/shulsen-lane-2.jpg',
    //   alt: 'Shulsen Lane',
    // },
    {
      id: '03',
      src: '/cw02.jpg',
      alt: 'Lofts on 34th',
    },
    {
      id: '04',
      src: '/cw03.jpg',
      alt: 'Lofts on 34th',
    },
    
    {
        id: '06',
        src: '/cw04.jpg',
        alt: 'Lofts on 34th',
      },
      {
        id: '07',
        src: '/cw05.jpg',
        alt: 'Lofts on 34th',
      },
      {
        id: '07',
        src: '/cw06.jpg',
        alt: 'Lofts on 34th',
      },
    //   {
    //     id: '05',
    //     src: '/DesertSage (5).jpg',
    //     alt: 'Shulsen Lane',
    //   },
    // {
    //   id: '06',
    //   src: '/Alpine_Flats_Theater_Room.jpg',
    //   alt: 'Revive Apartments',
    // },
   
  ]