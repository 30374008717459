import { Box, Container, Heading, Stack, Text, Link } from '@chakra-ui/react'

export const HomeTopContent = () => (
  <Box
    pt={{
      base: '35px',
      md: '70px',
    }}
    pb={{
      base: '12',
      md: '24',
    }}
    
    align={'left'}

  >
    <Container  maxWidth={'unset'}>
      <Stack spacing="1" ml={'7%'}  mr={'7%'}>
          <Heading
          
          >
            <Text  
            fontFamily={'montserrat, sans-serif'}
            fontWeight="bold"
            fontSize={['20px','23px','27px']}
            lineHeight={{ base: '35px', md: '45px' }}
            pb={{
              base: '4',
              md: '5',
            }}
            >
          Delivering a Premium Investment Experience in Utah and throughout the Mountain West Since 2007
            </Text>
          </Heading>
              <Text
                fontFamily={'Noto Sans, sans-serif'} 
                pb={{
                base: '4',
                md: '35px',
                }} 
                color="fg.muted"
                lineHeight={{ base: '28px', md: '45px' }}
                fontSize={{ base: '16px', md: '20px' }}
                >
          For institutional-level investors and developers, the Shin-Zylstra team is the number one choice in investments and evaluations. They rise above the rest with their consistency in professionalism and client relationships, expansive expertise in the market, and integrity in their work from start to finish. 
              </Text>
              <Link href={'/about'}>
                <Text 
                fontFamily={'Noto Sans, sans-serif'} 
                color={'#CBA689'}
                fontWeight={'medium'}
                fontSize={{ base: '16px', md: '20px' }}
                >Read More...</Text>
              </Link>
          
        </Stack>
    </Container>
  </Box>
)