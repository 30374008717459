import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { ProductCard } from './ProductCard'
import { Iproducts } from './institutionalData'
import { Pproducts } from './PrivateData'
import { ProductGrid } from './ProductGrid'

export const NavTabs = () => (
  <Tabs size='lg'>
  <TabList>
    <Tab 
     fontWeight={['normal', 'normal', 'normal']} // Adjust the font weight for different breakpoints
     fontSize={["sm", "lg", "20px"]}
     fontFamily={'montserrat, sans-serif'}
     _selected={{
       fontWeight: "bold", // Style for the selected tab
       color: "black", // Change this to the desired color
     }}
    >Institutional Investments</Tab>
    <Tab 
    fontWeight={['normal', 'normal', 'normal']} // Adjust the font weight for different breakpoints
          fontSize={["sm", "lg", "20px"]}
          fontFamily={'montserrat, sans-serif'}
          _selected={{
            fontWeight: "bold", // Style for the selected tab
            color: "black", // Change this to the desired color
          }}>Private Investments</Tab>
  </TabList>

  <TabPanels>
    <TabPanel>
    <ProductGrid>
      {Iproducts.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </ProductGrid>
    </TabPanel>
    <TabPanel>
    <ProductGrid>
      {Pproducts.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </ProductGrid>
    </TabPanel>
  </TabPanels>
</Tabs>
)