import { Box, Flex, Image, Text, } from '@chakra-ui/react';

const TeamDisplay = ({ imageSrc, name, title, email, license, number }) => {
  return (
    <Flex   alignItems="center" mb="8" mr={'15px'} w={['337px','','']} >
      {/* Image */}
      <Image src={imageSrc} alt={name} h={['100px','120px','120px']} borderRadius="full" mr={'10px'} />

      {/* Text */}
      <Box align={'left'}>
        <Text fontFamily={'Noto Sans, sans-serif'} fontSize={['15px','16px','14px']} fontWeight="bold" mb="1">
          {name}
        </Text>
        <Text fontFamily={'Noto Sans, sans-serif'} fontSize={['13px','14px','12px']} mb="1">
          {title}
        </Text>
        <Text fontFamily={'Noto Sans, sans-serif'} fontSize={['13px','14px','12px']} mb="1">
          {number}
        </Text>
        <Text fontFamily={'Noto Sans, sans-serif'} fontSize={['13px','14px','12px']} mb="1">
          {email}
        </Text>
        <Text fontFamily={'Noto Sans, sans-serif'} fontSize={['13px','14px','12px']} mb="1">
           {license}
        </Text>
      </Box>
    </Flex>
  );
};

export default TeamDisplay;