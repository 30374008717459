export const images = [
    {
      id: '01',
      src: '/Alpine_Flats_Clubhouse.jpg',
      alt: 'Alpine Flats',
    },
    {
      id: '02',
      src: '/Alpine Flats_Exterior_one.jpg',
      alt: 'Alpine Flats',
    },
    {
      id: '03',
      src: '/Alpine_Flats_Exterior.jpg',
      alt: 'Alpine Flats',
    },
    {
      id: '04',
      src: '/Alpine_Flats_Game_Room.jpg',
      alt: 'Alpine Flats',
    },
    {
      id: '05',
      src: '/Alpine_Flats_Interior.jpg',
      alt: 'Alpine Flats',
    },
    {
      id: '06',
      src: '/Alpine_Flats_Theater_Room.jpg',
      alt: 'Alpine Flats',
    },
   
  ]
