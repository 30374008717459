import { Box, Container, Heading, Stack, Text, Button, Link } from '@chakra-ui/react'

export const AboutTopContent = () => (
  <Box
    pt={{
      base: '4',
      md: '10',
    }}
    pb={{
      base: '12',
      md: '24',
    }}
    
    align={'left'}

  >
    <Container  maxWidth={'unset'}>
      <Stack spacing="1" ml={'7%'}  mr={'7%'}>
          <Heading
          
          >
            <Text  
            paddingTop={'25px'}
            fontFamily={'montserrat, sans-serif'}
            fontWeight="bold"
            fontSize={["16px", "25px","30px"]}
            lineHeight={['30px','45px','45px']}
            letterSpacing={['1.2px','1.6px','1.8px']}
            pb={{
              base: '4',
              md: '5',
            }}
            color={'#475253'}
            >
          THE SHIN ZYLSTRA TEAM LEVERAGES THEIR SKILL SETS TO CONSISTENTLY ALIGN INVESTORS TO OPPORTUNITIES
            </Text>
            <Text  
            fontFamily={'montserrat, sans-serif'}
            fontWeight="bold"
            fontSize={["22px","22px","22px"]}
            lineHeight={'45px'}
            pb={{
              base: '1',
              md: '1',
            }}
            color={'#475253'}
            >
          Team Vision
            </Text>
          </Heading>
              <Text 
              fontFamily={'montserrat, sans-serif'}
              pb={{
                base: '4',
                md: '5',
                }} 
                fontSize={["16px","18px","18px"]}
                color="fg.muted"
                lineHeight={['29px','30px','35px']}>
          For institutional-level investors and developers, the Shin-Zylstra team is the number one choice in investments and evaluations. They rise above the rest with their consistency in professionalism and client relationships, expansive expertise in the market, and integrity in their work from start to finish. 
              </Text>
        </Stack>
    </Container>
    <Box >
      <Link  href={"/contact"} >
      <Button _hover={{ backgroundColor: '#a2765e' }} height={'50px'} fontFamily={'montserrat, sans-serif'} bgColor={'#CBA689'} color={'white'} width={'180px'} marginInline={['26%','42%','42%']} marginTop={['25px','50px','50px']}>Contact Us</Button>
    </Link>
    </Box>
    
    
    <Box
      as="section"
      mt={'75px'}
      py="12"
      position="relative"
      h={{ base: '133px', md: '385px' }}
      bgImage="/jake-weirick.png"
      bgSize="cover"
      bgPosition="center"
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: 'full',
        // bg: 'blackAlpha.700',
        position: 'absolute',
        inset: 0,
        zIndex: 0,
      }}
      style={{
        borderBottom: '20px solid #CCCCCC',
        position: 'relative', // Ensure the border appears on top of the background image
        zIndex: 1, // Ensure the border appears above the pseudo-element (_after)
      }}
      ></Box>
  </Box>
)