import { Flex, Box, Center, Text } from '@chakra-ui/react';
import TeamDisplay from './TeamDisplay';

const TeamInfo = () => {
  return (
    <Box px={{
      base: '4',
      md: '5%',
    }} >
      <Flex alignItems={'center'} justifyContent={'center'} mt={'75px'} ml={'3%'} mr={'3%'} flexDirection={['column','column','row']}> 
      {/* Danny */}
      <TeamDisplay
        imageSrc="/danny-shin-circle.png"
        name="Danny Shin"
        title="Senior Managing Director Investments"
        number="801.736.2628"
        email="dshin@ipausa.com"
        license="UT 6298928-SA00 | ID SP38035"
      />

      {/* Brock */}
      <TeamDisplay
        imageSrc="/brock-zylstra-circle.png" // Add the image source for the second employee
        name="Brock Zylstra"
        title="Senior Vice President Investments"
        number="801.736.2642"
        email="bzylstra@ipausa.com"
        license="UT 8726586-SA00 | ID SP47190"
      />

       {/* Jake */}
       <TeamDisplay
        imageSrc="/Jake_Circle.png" // Add the image source for the second employee
        name="Jake Miles"
        title="Senior Associate"
        number="801.736.2654"
        email="jake.miles@marcusmillichap.com"
        license="UT 11449836-SA00 | ID SP53647"
      />
    </Flex>
    <Box textAlign={["center","center","center"]} mt={'50px'} >
      {/* Title */}
      <Text fontFamily={'montserrat, sans-serif'}  fontSize={["22px","28px","38px"]} fontWeight="bold" mb="4" letterSpacing={'1.8px'}>
        SUBMIT AN INQUIRY
      </Text>

      {/* Paragraph */}
      <Center mt={['25px','50px','50px']}>
        <Text 
        marginBottom={'25px'}
        fontFamily={'montserrat, sans-serif'} textAlign="left" width={['72%','85%','85%']} fontSize={["14px","18px","19px"]} lineHeight={['30px','35px','38px']}>
        Contact us If you have any specific questions about our services or are looking to evaluate 
        your investment portfolio. Please fill out the form below and we will reach out to you as soon as possible.
        </Text>
      </Center>
    </Box>
    </Box>
    
  );
};

export default TeamInfo;