import { Box, Container, Heading, Stack, Text } from '@chakra-ui/react'

export const HomeLowerContent = () => (
  <Box
  px={'4%'}
    as="section"
    pt={{
      base: '25px',
      md: '105px',
    }}
    pb={{
      base: '12px',
      md: '18px',
    }}
    align={'left'}
  >
    <Container width={'94%'} maxWidth={'94%'}>
        <Stack spacing="1">
          <Heading
            fontFamily={'montserrat, sans-serif'}
            fontSize={['19px','24px','34px']}
            fontWeight="bold"
            letterSpacing={'1.8px'}
          >
           TROPHY ASSETS
          </Heading>
          <Heading
          fontFamily={'Noto Sans, sans-serif'
        }
          pt={{
            base: '18px',
            md: '26px',
          }}
            size={{
              base: 'm',
              md: 'l',
            }}
            fontWeight="light"
            fontSize={{
              base: '15px',
              md: '22px',
            }}
            lineHeight={['28px','35px','45px']}
          >
           For more than a decade, the team has worked with high-quality assets of varying classes, sizes, and statuses.
          </Heading>
        </Stack>
    </Container>
  </Box>
)