export const Pproducts = [
    {
      id: '1',
      name: 'The Grand on 800 North',
      title: '',
      imageUrl:
        './Grand Portfolio_Portfolio Thumbnail2.png',
      description: '',
        productLink: 'https://www.crexi.com/properties/1568655/utah-the-grand-on-800-north',
        isClosed: false,
    },
    {
        id: '1',
        name: 'The Grand at Midvale',
        title: '',
        imageUrl:
          './Grand Portfolio_Portfolio Thumbnail.png',
        description: '',
          productLink: 'https://www.crexi.com/properties/1568650/utah-the-grand-at-midvale',
          isClosed: false,
      },
 
    ]