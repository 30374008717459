import { Box, Flex, Image, Text, Button, Link } from '@chakra-ui/react';

const Team = () => {
  return (
    <Box>
      {/* Danny  */}
        <Flex align="center" display={['flex','flex', 'flex']} flexDir={['column','row','row']} paddingTop={'80px'} px={["6","",""]}>
      {/* Image */}
      <Image src="/danny-shin-portrait.png" alt="Danny Shin" h={["375px","490px","490px"]} mr="4" paddingLeft={[0,0,'10%']}/>

      {/* Text and Button */}
      <Box align={'left'} paddingLeft={'5%'} paddingRight={'10%'}>
        {/* Name */}
        <Text fontFamily={'montserrat, sans-serif'} fontSize={["20px","26px","30px"]} fontWeight="bold" mb="4" pt={["6","",""]} color={'#475253'}>
          Danny Shin
        </Text>

        {/* Paragraph */}
        <Text fontFamily={'montserrat, sans-serif'} mb="4" fontSize={["14px","16px","16px"]} lineHeight={['28px','30px','32px']} paddingBottom={'25px'}>
        Starting in the industry in 2006, Danny's thorough insight rises to the tip in 
        the multifamily real estate industry. Devoted to his trade, Danny leverages his 
        skills in critical thinking and business acumen. This experience, coupled with 
        his undergraduate degree in Business and Statistics, has provided him with valuable 
        skills that are exercised daily in real estate negotiations, educating clients 
        and sellers, and effectively leading the team's operations. Danny ensures that 
        each interaction succeeds with his leadership, critical thinking skills, and 
        loyalty to his team and clients.
        </Text>

        {/* Contact Button */}
        <Link href={"/contact"} >
          <Button _hover={{ backgroundColor: 'black' }} fontWeight={'normal'} fontFamily={'montserrat, sans-serif'} colorScheme="blue" bgColor={'#475253'}>Contact</Button>
        </Link>
        
      </Box>
    </Flex>
{/* Brock  */}
    <Flex display={['flex','flex', 'flex']} flexDir={['column','row','row']} align="center" pt={'75px'} px={["6","",""]}  paddingTop={['75px','150px','150px']}>
      {/* Image */}
      <Image src="/brock-zylstra-portrait.png" alt="Brock Zylstra" h={["375px","490px","490px"]} mr="4" paddingLeft={[0,0,'10%']} />

      {/* Text and Button */}
      <Box align={'left'} paddingLeft={'5%'} paddingRight={'10%'}>
        {/* Name */}
        <Text fontFamily={'montserrat, sans-serif'} fontSize={["20px","26px","30px"]} fontWeight="bold" mb="4" pt={["6","",""]} color={'#475253'}>
        Brock Zylstra
        </Text>

        {/* Paragraph */}
        <Text fontFamily={'montserrat, sans-serif'} mb="4"  fontSize={["14px","16px","16px"]} lineHeight={['28px','30px','32px']} paddingBottom={'25px'}>
        Brock contributes his raw authenticity to the brokerage business by combining integrity 
        and passion to connect and guide each client as they work to achieve their goals. 
        His infectious energy and candor allow him to easily connect with his peers and clients. 
        He graduated from Brigham Young University with degrees in Commercial Management and 
        Business. Joining the industry in 2013, Brock delivers a consistent drive that can't be 
        discounted, possessing a unique ability to strategize and come out on top.
        </Text>

        {/* Contact Button */}
        <Link href={"/contact"}>
          <Button _hover={{ backgroundColor: 'black' }} fontWeight={'normal'} fontFamily={'montserrat, sans-serif'} colorScheme="blue" bgColor={'#475253'}>Contact</Button>
        </Link>
        
      </Box>
    </Flex>
{/* Jake  */}
    <Flex display={['flex','flex', 'flex']} flexDir={['column','row','row']} align="center" pt={'75px'} px={["6","",""]}  paddingTop={['75px','150px','150px']}>
      {/* Image */}
      <Image src="/jake-miles-portrait.png" alt="Jake Miles" h={["375px","490px","490px"]} mr="4" paddingLeft={[0,0,'10%']} />

      {/* Text and Button */}
      <Box align={'left'} paddingLeft={'5%'} paddingRight={'10%'}>
        {/* Name */}
        <Text fontFamily={'montserrat, sans-serif'} fontSize={["20px","26px","30px"]} fontWeight="bold" mb="4" pt={["6","",""]} color={'#475253'}>
        Jake Miles
        </Text>

        {/* Paragraph */}
        <Text fontFamily={'montserrat, sans-serif'} mb="4"  fontSize={["14px","16px","16px"]} lineHeight={['28px','30px','32px']} paddingBottom={'25px'}>
        Jake works with a focus on the acquisition and disposition of multifamily assets in the Utah and Idaho markets. He brings a unique perspective and skillset to the multifamily business through his diverse background in loan portfolios and statistical data. As a part of the Shin-Zylstra team, he is able to ensure success for all through his strong work ethic and competitive spirit. Jake has proven to be a valuable asset to the group and has shown rapid success in all his transactions.
        </Text>

        {/* Contact Button */}
        <Link href={"/contact"}>
          <Button _hover={{ backgroundColor: 'black' }} fontWeight={'normal'} fontFamily={'montserrat, sans-serif'} colorScheme="blue" bgColor={'#475253'}>Contact</Button>
        </Link>
        
      </Box>
    </Flex>

    </Box>
    
  );
};

export default Team;






