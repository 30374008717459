import { Box, Button, Container, Stack, Text, Link } from '@chakra-ui/react'

export const PreFooter = () => (
  <Box
    as="section"
    height={['200px','250px','250px']}
    bg={'#F5F5F5'}
  >
    <Box py={{ base: 0, sm: 0, md: 12 }}>
      <Container maxWidth={'85%'} 
        py={{
          base: '0',
          md: '3.5',
        }}
      >
        <Stack
          spacing="4"
          
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <Box
            pe={{
              base: '4',
              md: '0',
            }}
          >
            <Text pt={'25px'} fontSize={["18px", "22px", "22px"]} fontFamily={'Noto Sans, sans-serif'} pr={{base: '0px', md: '10%' }} textAlign={{base: 'center', md: 'left' }} color="fg.muted">Contact us with questions or inquiries about your investment portfolio.</Text>
          </Box>
          <Stack
            direction={{
              base: 'row',
              md: 'row',
            }}
            spacing="3"
            align={{
              base: 'center',
              md: 'center',
            }}
            justifyContent={'center'}
          >
            <Link href={'/contact'}>
              <Button width={'135px'} color={'#fff'} backgroundColor={'#CBA689'} fontWeight={'400'} fontFamily={'montserrat, sans-serif'} _hover={{ backgroundColor: '#a2765e' }}>Contact Us</Button>
            </Link>
            <Link href={'/about'}>
              <Button width={'135px'} color={'black'} backgroundColor={'#fff'} fontWeight={'400'} fontFamily={'montserrat, sans-serif'} >Learn More</Button>
            </Link>
            
          </Stack>
        </Stack>
      </Container>
    </Box>
  </Box>
)