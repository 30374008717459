import { extendTheme, theme as baseTheme } from '@chakra-ui/react'

const colors = { 
  // ...baseTheme.colors,
  // initialColorMode: 'light', // Set the initial color mode to "light"
  // brand: baseTheme.colors.blue,
  // bg: '#FFFFFF',
  primary: {
    100: "#E5FCF1",
    200: "s#27EF96",
    300: "#10DE82",
    400: "#0EBE6F",
    500: "#0CA25F",
    600: "#0A864F",
    700: "#086F42",
    800: "#075C37",
    900: "#064C2E"
  },
};

const customTheme = extendTheme({ colors });

export default customTheme;
