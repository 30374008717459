export const images = [
    {
      id: '01',
      src: '/_Hidden Villas_Aerial Square.png',
      alt: 'hidden villas',
    },
    {
      id: '02',
      src: '/_Hidden Villas_Exterior Header.png',
      alt: 'hidden villas',
    },
    {
      id: '03',
      src: '/Hidden Villas_Demographic.jpg',
      alt: 'hidden villas',
    },
    {
      id: '04',
      src: '/Hidden Villas_Exterior.jpg',
      alt: 'hidden villas',
    },
    {
      id: '05',
      src: '/Hidden Villas_Exterior2.jpg',
      alt: 'hidden villas',
    },
    {
        id: '06',
        src: '/Hidden Villas_Kitchen.jpg',
        alt: 'hidden villas',
      },
      {
        id: '07',
        src: '/Hidden Villas_Living Room.jpg',
        alt: 'hidden villas',
      },
    // {
    //   id: '06',
    //   src: '/Alpine_Flats_Theater_Room.jpg',
    //   alt: 'Revive Apartments',
    // },
   
  ]