import { Box, Button, Center, Heading, Link, Image, Text } from '@chakra-ui/react'

export const HomeHero = () => {
  return (
    <Box
    
      
      bg="gray.800"
      py="12"
      position="relative"
      h={{ base: '500px', md: '800px' }}
      bgImage="/Downtown_SLC.jpg"
      bgSize="auto"
      bgPosition="bottom"
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: 'full',
        bg: 'blackAlpha.700',
        position: 'absolute',
        inset: 0,
        zIndex: 0,
      }}
    >
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
        h="full"
        zIndex={1}
        position="relative"
      >
        <Center flexDirection="column" textAlign="center" color="white" h="full">
          <Image pt={'10%'} height={['28%','30%','30%']} src='/SZ_logo_w.png'  ></Image>
          <Heading 
          fontFamily={"montserrat, sans-serif"}
          size={{ base: 'l', md: 'xl' }}
          fontSize={['18px','33px','38px']}
           fontWeight="extrabold" 
           pt={'5%'}>
            Passion For Multifamily. Unrivaled Execution.
          </Heading>
          <Text w={'85%'}
          pt={['15px','30px','30px']}
          fontSize={['15px','22px','25px']}
          >
          Leading the Multifamily Market with Exceptional Service and a Decade of Experience for Unparalleled Investment Success.
          </Text>
          <Link href={'/contact'}>
            <Button 
            w={'150px'}
            fontFamily={'montserrat'} 
            bgColor={'#CBA689'}
            color={'white'} 
            mt={['20px','50px','50px']} 
            fontWeight={"medium"} 
            fontSize={{ base: 'sm', md: '14px' }}
            _hover={{ backgroundColor: '#a2765e' }}
            >
              Get in Touch
            </Button>
          </Link>
            
        </Center>
      </Box>
      <Box
        display={{ base: 'none', md: 'block' }}
        position="absolute"
        zIndex={2}
        w="full"
        bottom="0"
        py="4"
        
      >
      </Box>
    </Box>
  )
}