import React, { useState } from 'react';
import { Box, Flex, Text, Image, Link } from '@chakra-ui/react';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';

const Card = ({ title, bedBath, sqFt, price }) => (
  <Box fontFamily={'montserrat, sans-serif'} mx={'20px'}>
  <Box bgColor={'#EAE6DC'} h={'255px'} w={'280px'} borderRadius="30px 30px 0 0" mt={'50px'} >
  <Text fontFamily={'montserrat, sans-serif'} fontSize={['20px','23px','26px']} fontWeight="bold" textAlign="center"  paddingTop={'40px'} color={'#475253'}>
  {title}
      </Text>
       {/** BED / BATH */}
       <Box display="flex" alignItems="center" justifyContent="space-between" mb="2" marginLeft={'45px'} marginRight={'45px'} marginTop={'40px'}>
        <Text fontSize={["16px","16px","18px"]} fontWeight="normal" >{bedBath}</Text>
      </Box>
      <Box borderBottom="1px solid" borderColor={'#CBA689'} mb="2" marginLeft={'45px'} marginRight={'45px'}></Box>
       {/** BED / BATH */}
       <Box display="flex" alignItems="center" justifyContent="space-between" mb="2" marginLeft={'45px'} marginRight={'45px'}>
        <Text fontSize={["16px","16px","18px"]} fontWeight="normal" >{sqFt}</Text>
      </Box>
      <Box borderBottom="1px solid" borderColor={'#CBA689'} mb="2" marginLeft={'45px'} marginRight={'45px'}></Box>
       {/** BED / BATH */}
       <Box display="flex" alignItems="center" justifyContent="space-between" mb="2" marginLeft={'45px'} marginRight={'45px'}>
        <Text fontSize={["16px","16px","18px"]} fontWeight="normal" >{price}</Text>
      </Box>
      <Box borderBottom="1px solid" borderColor={'#CBA689'} mb="2" marginLeft={'45px'} marginRight={'45px'}></Box>
  </Box>
  {/* BOTTOM BOX */}
  <Box bgColor={'#CBA689'} h={'50px'} w={'280px'} borderRadius="0 0 30px 30px">
  </Box>
</Box>
  //  <Link>
  //   <Text fontFamily={'montserrat, sans-serif'} fontSize={['20px','23px','26px']}  textAlign="center"  paddingTop={'20px'} color={'white'}>
  //   Floor Plan <Image marginLeft={'10px'} src={"/download.png"} display={'inline'} height={'25px'} width={'25px'}>
  //   </Image>
  //     </Text>
  //   </Link> 
  
);
const ScrollableCardList = ({ data, startIndex, setStartIndex }) => {
  const endIndex = (startIndex + 2) % data.length;

  const getCardAtIndex = (index) => {
    const dataLength = data.length;
    return data[(index + dataLength) % dataLength];
  };

  return (
    <Flex overflowX="hidden" p="30px" justifyContent="center">
      {[0, 1, 2].map((index) => (
        <Card key={index} {...getCardAtIndex(startIndex + index)} />
      ))}
    </Flex>
  );
};


// const ScrollableCardList = ({ data, startIndex, setStartIndex }) => {
//   const endIndex = startIndex + 2;

//   return (
//     <Flex overflowX="hidden" p="30px" justifyContent={'space-around'}>
//       {data.slice(startIndex, endIndex + 1).map((item, index) => (
//         <Card key={index} {...item} />
//       ))}
//     </Flex>
//   );
// };
// const ScrollableCardList = ({ data }) => {
//   return (
//     <Flex overflowX="" p="30px">
//       {data.map((item, index) => (
//         <Card key={index} {...item} />
//       ))}
//     </Flex>
//   );
// };

const CardMapper = (props) => {
  

  const [startIndex, setStartIndex] = useState(0);

  const handleNext = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % props.data.length);
  };

  const handlePrev = () => {
    setStartIndex((prevIndex) => (prevIndex - 1 + props.data.length) % props.data.length);
  };

  return (
    <Box fontFamily="montserrat, sans-serif" mx="30px" display={['none','none','block']}>
      <ScrollableCardList data={props.data} startIndex={startIndex} setStartIndex={setStartIndex}  />
      <Flex justifyContent="space-between" mt={'-220px'} mb={'200px'}>
        <Box ml={'70px'} onClick={handlePrev} as={IoChevronBackOutline} size="40px" color="#CBA689" cursor="pointer" />
        <Box mr={'70px'} onClick={handleNext} as={IoChevronForwardOutline} size="40px" color="#CBA689" cursor="pointer" />
      </Flex>
    </Box>
  );
};

export default CardMapper;